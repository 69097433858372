import {
  EuiButton,
  EuiFlexGroup,
  EuiImage,
  EuiProvider,
  EuiThemeProvider,
} from "@elastic/eui";
import { Outlet } from "react-router";
import logo from "./assets/darkLogo.png";
import theme from "./euiTheme.json";

const AuthLayout = () => (
  <EuiProvider>
    <EuiThemeProvider modify={theme}>
      {/* Header */}
      <header style={{ height: "64px" }}>
        <EuiFlexGroup
          direction="row"
          justifyContent="flexEnd"
          alignItems="center"
          gutterSize="none"
        >
          <EuiButton disabled>Language</EuiButton>
          <EuiImage
            alt="GreenEarthX"
            src={logo}
            size={80}
            css={{ padding: "1rem", marginRight: "1rem" }}
          />
        </EuiFlexGroup>
      </header>

      {/* Main Content */}
      <main>
        <Outlet /> {/* Render child routes here */}
      </main>
    </EuiThemeProvider>
  </EuiProvider>
);

export default AuthLayout;
