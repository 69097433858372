import ApiHandler from "./apiHandler";

const api = new ApiHandler();

export const login = async (email: string, password: string) => {
  try {
    const payload = {
      User: {
        Email: email,
        Password: password,
      },
    };

    const response = await api.post<{
      User: { ApiKey: string; Id: string; Name: string };
    }>("/login", payload);

    if (response.status === 200) {
      // Login successful
      return response.data;
    } else {
      // Handle specific API response codes if needed
      throw new Error(response.message || "Login failed");
    }
  } catch (error: any) {
    console.error("Error during login:", error.message);
    throw new Error(error.message || "Unexpected error during login");
  }
};
