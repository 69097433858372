import { useEffect, useState } from "react";

export interface TimerInstance {
  startTimer: () => void;
  resetTimer: () => void;
  getCurrentTime: () => number;
  isActive: boolean;
}

interface TimerProps {
  initialSeconds?: number;
}

const TimerComponent = ({ initialSeconds = 0 }: TimerProps): TimerInstance => {
  const [time, setTime] = useState<number>(initialSeconds);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<number | null>(null);
  const [endTime, setEndTime] = useState<number | null>(null);

  const startTimer = () => {
    if (isActive || time <= 0) return;

    const now = Date.now();
    setStartTime(now);
    setEndTime(now + time * 1000);
    setIsActive(true);
  };

  const resetTimer = () => {
    setStartTime(null);
    setEndTime(null);
    setIsActive(false);
    setTime(initialSeconds);
  };

  const getCurrentTime = () => time;

  useEffect(() => {
    if (!isActive || startTime === null || endTime === null) return;

    const intervalId = setInterval(() => {
      const now = Date.now();
      const remainingTime = Math.max(Math.ceil((endTime - now) / 1000), 0);

      setTime(remainingTime);

      if (remainingTime <= 0) {
        clearInterval(intervalId);
        setIsActive(false);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [isActive, startTime, endTime]);

  return {
    startTimer,
    resetTimer,
    getCurrentTime,
    isActive,
  };
};

export default TimerComponent;
