import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import { useFormik } from "formik";
import { useState } from "react";
import BusinessEmailValidatorComponent, {
  BusinessEmailValidatorInstance,
} from "../components/businessEmailValidator";
import { forgotPassword } from "../services/forgotPassword";

interface FormValues {
  email: string;
}

interface FormErrors {
  email?: string;
}

const ForgotPassword = () => {
  const [requestSent, setRequestSent] = useState(false);
  const emailValidator: BusinessEmailValidatorInstance =
    BusinessEmailValidatorComponent();
  const emailFormik = useFormik({
    initialValues: {
      email: "",
    },
    validateOnChange: true,
    validate: (values: FormValues): FormErrors => {
      const errors: FormErrors = {};

      const emailError = emailValidator.validateEmail(values.email);

      if (emailError) {
        errors.email = emailError; // Assign only if there is an error
      }

      return errors;
    },
    onSubmit: async (values: FormValues) => {
      const emailId = values.email;

      try {
        const message = await forgotPassword(emailId);
        console.log("Response:", message);
        setRequestSent(true);
      } catch (error: any) {
        console.error("Forgot Password failed:", error.message);
      }
    },
  });
  return (
    <EuiFlexGroup
      direction="column"
      alignItems="stretch"
      style={{ height: "calc(100vh - 64px)" }}
    >
      <EuiFlexItem grow={1}>
        <EuiFlexGroup
          justifyContent="center"
          alignItems="center"
          direction="column"
          style={{ height: "100%" }}
        >
          <EuiFlexItem grow={0}>
            <EuiPanel style={{ width: "400px" }}>
              <EuiFlexGroup
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                {!requestSent ? (
                  <>
                    <EuiFlexItem style={{ width: "100%", textAlign: "center" }}>
                      <EuiTitle>
                        <h1>Reset your password</h1>
                      </EuiTitle>
                      <EuiSpacer />
                      <EuiText size="s">
                        <p>
                          Tell us the email you registered with and we'll send
                          you a link to reset your password.
                        </p>
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem style={{ width: "100%" }}>
                      <EuiSpacer />
                      <EuiForm
                        component="form"
                        onSubmit={emailFormik.handleSubmit}
                      >
                        <EuiFormRow
                          isInvalid={
                            !!emailFormik.errors.email &&
                            emailFormik.touched.email
                          }
                          error={emailFormik.errors.email}
                        >
                          <EuiFieldText
                            type="email"
                            placeholder="Business Email"
                            name="email"
                            value={emailFormik.values.email}
                            onChange={emailFormik.handleChange}
                            onBlur={emailFormik.handleBlur}
                            isInvalid={
                              !!emailFormik.errors.email &&
                              emailFormik.touched.email
                            }
                          />
                        </EuiFormRow>
                        <EuiSpacer />
                        <EuiFlexGroup
                          justifyContent="center"
                          alignItems="center"
                          direction="column"
                        >
                          <EuiButton
                            fullWidth
                            fill
                            isDisabled={
                              !emailFormik.values.email ||
                              (!!emailFormik.errors.email &&
                                emailFormik.touched.email)
                            }
                            type="submit"
                          >
                            Reset password
                          </EuiButton>
                          <EuiButtonEmpty
                            href="/"
                            iconSide="left"
                            iconType="arrowLeft"
                          >
                            Back to log in
                          </EuiButtonEmpty>
                        </EuiFlexGroup>
                      </EuiForm>
                    </EuiFlexItem>
                  </>
                ) : (
                  <>
                    <EuiFlexItem style={{ width: "100%", textAlign: "center" }}>
                      <EuiTitle>
                        <h1>Reset your password</h1>
                      </EuiTitle>
                      <EuiSpacer />
                      <EuiText size="s">
                        <p>
                          We'll email you a link to reset your password if we
                          find your account
                        </p>
                      </EuiText>
                    </EuiFlexItem>
                  </>
                )}
              </EuiFlexGroup>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem style={{ width: "300px", textAlign: "center" }} grow={0}>
            <EuiText size="s">
              <p>
                Forgot the email you used to register? Contact <br />
                <a href="mailto:support@greenearthx.com">
                  support@greenearthx.com
                </a>
              </p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default ForgotPassword;
