import {
  EuiButton,
  EuiFieldPassword,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import { useFormik } from "formik";
import BusinessEmailValidatorComponent, {
  BusinessEmailValidatorInstance,
} from "../components/businessEmailValidator";
import { login } from "../services/login"; // Adjust the path as needed

interface FormValues {
  email: string;
  password: string;
}

interface FormErrors {
  email?: string;
}

const Login = () => {
  const emailValidator: BusinessEmailValidatorInstance =
    BusinessEmailValidatorComponent();
  const loginFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnChange: true,
    validate: (values: FormValues): FormErrors => {
      const errors: FormErrors = {};

      const emailError = emailValidator.validateEmail(values.email);
      if (emailError) {
        errors.email = emailError; // Assign only if there is an error
      }
      return errors;
    },
    onSubmit: async (values: FormValues) => {
      console.log("Submit");
      try {
        const email = values.email;
        const password = values.password;

        const userData = await login(email, password);
        console.log("Login successful:", userData);
        // TODO: What do we need to do with this?
      } catch (error: any) {
        console.error("Login failed:", error.message);
      }
    },
  });

  return (
    <EuiFlexGroup
      direction="column"
      alignItems="stretch"
      style={{ height: "calc(100vh - 64px)" }}
    >
      <EuiFlexItem grow={1}>
        <EuiFlexGroup
          justifyContent="center"
          alignItems="center"
          direction="column"
          style={{ height: "100%" }}
        >
          <EuiFlexItem grow={0}>
            <EuiPanel style={{ width: "400px" }}>
              <EuiFlexGroup
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <EuiFlexItem style={{ width: "100%", textAlign: "center" }}>
                  <EuiTitle>
                    <h1>Log in to GEX Terminal</h1>
                  </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem style={{ width: "100%" }}>
                  <EuiSpacer />
                  <EuiForm component="form" onSubmit={loginFormik.handleSubmit}>
                    <EuiFormRow
                      isInvalid={
                        !!loginFormik.errors.email && loginFormik.touched.email
                      }
                      error={loginFormik.errors.email}
                    >
                      <EuiFieldText
                        type="email"
                        placeholder="Business Email"
                        name="email"
                        value={loginFormik.values.email}
                        onChange={loginFormik.handleChange}
                        onBlur={loginFormik.handleBlur}
                        isInvalid={
                          !!loginFormik.errors.email &&
                          loginFormik.touched.email
                        }
                      />
                    </EuiFormRow>
                    <EuiFormRow>
                      <EuiFieldPassword
                        name="password"
                        placeholder="Password"
                        type="dual"
                        value={loginFormik.values.password}
                        onChange={loginFormik.handleChange}
                        onBlur={loginFormik.handleBlur}
                      />
                    </EuiFormRow>
                    <EuiSpacer />
                    <EuiFlexGroup
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                    >
                      <EuiButton
                        fullWidth
                        fill
                        isDisabled={
                          !loginFormik.values.email ||
                          !loginFormik.values.password ||
                          (!!loginFormik.errors.email &&
                            loginFormik.touched.email)
                        }
                        type="submit"
                      >
                        Log in
                      </EuiButton>
                      <EuiText size="s">
                        <a href="reset">Forgot password?</a>
                      </EuiText>
                    </EuiFlexGroup>
                  </EuiForm>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiPanel>
            <EuiSpacer />
            <EuiPanel color="transparent">
              <EuiFlexGroup
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <EuiText size="s">
                  <p>Not a GEX user?</p>
                </EuiText>
                <EuiButton href="register">Sign up</EuiButton>
              </EuiFlexGroup>
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default Login;
