import ApiHandler from "./apiHandler";

const api = new ApiHandler();

// Register Function
export const register = async (email: string) => {
  try {
    const payload = {
      Email: email,
    };

    const response = await api.post<{
      SignUp: { Email: string; Id: string; Validated: string };
    }>("/sign_ups/register", payload);

    if (response.status === 200) {
      // Return the SignUp object on success
      return response.data.SignUp;
    } else {
      // Handle specific API response codes if needed
      throw new Error(response.message || "Registration failed");
    }
  } catch (error: any) {
    console.error("Error in register:", error.message);
    throw new Error(
      error.message || "Unexpected error occurred during registration"
    );
  }
};

// Validate OTP Function
export const validateOtp = async (signUpRequestId: string, otp: string) => {
  try {
    const payload = {
      Otp: otp,
    };

    const response = await api.post<{
      SignUp: {
        Email: string;
        Id: string;
        User: {
          ApiKey: string;
          Id: string;
          LongName: string;
          Name: string;
        };
        Validated: string;
      };
    }>(`/sign_ups/validateOtp/${signUpRequestId}`, payload);

    if (response.status === 200) {
      // Return the SignUp object on success
      return response.data.SignUp;
    } else {
      // Handle specific API response codes if needed
      throw new Error(response.message || "OTP validation failed");
    }
  } catch (error: any) {
    console.error("Error in validateOtp:", error.message);
    throw new Error(
      error.message || "Unexpected error occurred during OTP validation"
    );
  }
};

// Save Password Function
export const savePassword = async (
  userId: string,
  newPassword: string,
  apiKey: string
) => {
  try {
    const payload = {
      Id: userId,
      ChangePassword: newPassword,
    };

    const response = await api.post<{ User: any }>("/users/save", payload, {
      ApiKey: apiKey,
    });

    if (response.status === 200) {
      // Return the User object on success
      return response.data.User;
    } else {
      // Handle specific API response codes if needed
      throw new Error(response.message || "Failed to save user data");
    }
  } catch (error: any) {
    console.error("Error in saveUser:", error.message);
    throw new Error(
      error.message || "Unexpected error occurred while saving user data"
    );
  }
};
