import ApiHandler from "./apiHandler";

const api = new ApiHandler();

export const forgotPassword = async (emailId: string) => {
  try {
    const response = await api.get<{
      SWFHttpResponse: { Status: string; Message: string };
    }>(`/users/forgot_password/${emailId}`);

    if (response.data.SWFHttpResponse.Status === "OK") {
      return response.data.SWFHttpResponse.Message;
    } else {
      // Handle specific API response codes if needed
      throw new Error(
        response.data.SWFHttpResponse.Message || "Forgot password failed"
      );
    }
  } catch (error: any) {
    console.error("Error in forgotPassword:", error.message);
    throw new Error(
      error.message ||
        "Unexpected error occurred while sending forgot password email"
    );
  }
};
