import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { useState } from "react";
import EmailPage from "../components/registration/emailPage";
import OtpPage from "../components/registration/otpPage";
import PasswordPage from "../components/registration/passwordPage";
import TimerComponent, { TimerInstance } from "../components/timerComponent";

const Register = () => {
  const [page, setPage] = useState<number>(0);
  const [email, setEmail] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [apiKey, setApiKey] = useState<string>("");

  const timer: TimerInstance = TimerComponent({ initialSeconds: 120 });

  const renderPageContent = (page: Number) => {
    switch (page) {
      case 0:
        return (
          <EmailPage
            email={email}
            timer={timer}
            setEmail={setEmail}
            setUserId={setUserId}
            setPage={setPage}
          />
        );
      case 1:
        return (
          <OtpPage
            email={email}
            userId={userId}
            timer={timer}
            setApiKey={setApiKey}
            setPage={setPage}
          />
        );
      case 2:
        return (
          <PasswordPage userId={userId} apiKey={apiKey} setPage={setPage} />
        );
      default:
        return <div>Default content</div>;
    }
  };

  return (
    <EuiFlexGroup
      direction="column"
      alignItems="stretch"
      style={{ height: "calc(100vh - 64px)" }}
    >
      <EuiFlexItem grow={1}>
        <EuiFlexGroup
          justifyContent="center"
          alignItems="center"
          direction="column"
          style={{ height: "100%" }}
        >
          <EuiFlexItem grow={0}>{renderPageContent(page)}</EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default Register;
