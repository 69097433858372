import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldPassword,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import { useFormik } from "formik";
import { savePassword } from "../../services/register";

interface FormValues {
  password: string;
  confirmPassword: string;
}

interface FormErrors {
  password?: string;
  confirmPassword?: string;
}

const PasswordPage = ({
  userId,
  apiKey,
  setPage,
}: {
  userId: string;
  apiKey: string;
  setPage: (page: number) => void;
}) => {
  const createPasswordFormik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validateOnChange: true,
    validate: (values: FormValues): FormErrors => {
      const errors: FormErrors = {};

      const validatePassword = (password: string): string | undefined => {
        if (!password) return "Password is required";
        if (password.length < 8)
          return "Password must be at least 8 characters long";
        if (!/[a-z]/.test(password))
          return "Password must contain at least one lowercase letter";
        if (!/[A-Z]/.test(password))
          return "Password must contain at least one uppercase letter";
        if (!/\d/.test(password))
          return "Password must contain at least one digit";
        if (!/[!@#$%^&*]/.test(password))
          return "Password must contain at least one special character";
      };

      errors.password = validatePassword(values.password);

      if (!values.confirmPassword) {
        errors.confirmPassword = "Confirm Password is required";
      } else if (values.confirmPassword !== values.password) {
        errors.confirmPassword = "Passwords do not match";
      }

      return errors;
    },
    onSubmit: async (values: FormValues) => {
      const password = values.password;
      try {
        // Call the set password API
        const savePasswordData = await savePassword(userId, password, apiKey);
        console.log("Password Save Successful:", savePasswordData);
      } catch (error: any) {
        console.error("Password Failed failed:", error.message);
      }
    },
  });

  return (
    <EuiPanel style={{ width: "400px" }}>
      <EuiFlexGroup
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <EuiFlexItem style={{ width: "100%", textAlign: "left" }}>
          <EuiTitle>
            <h1>Sign up for GEX Terminal</h1>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem style={{ width: "100%", textAlign: "left" }}>
          <EuiText>
            <p>Choose a password with</p>
            <ul>
              <li>At least 8 characters</li>
              <li>One upper case character</li>
              <li>One lower case character</li>
              <li>One number</li>
              <li>One symbol</li>
            </ul>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem style={{ width: "100%" }}>
          <EuiForm component="form">
            <EuiFormRow
              isInvalid={
                !!createPasswordFormik.errors.password &&
                createPasswordFormik.touched.password
              }
              error={createPasswordFormik.errors.password}
            >
              <EuiFieldPassword
                placeholder="Password"
                name="password"
                value={createPasswordFormik.values.password}
                onChange={createPasswordFormik.handleChange}
                onBlur={createPasswordFormik.handleBlur}
                isInvalid={
                  !!createPasswordFormik.errors.password &&
                  createPasswordFormik.touched.password
                }
              />
            </EuiFormRow>
            <EuiFormRow
              isInvalid={
                !!createPasswordFormik.errors.confirmPassword &&
                createPasswordFormik.touched.confirmPassword
              }
              error={createPasswordFormik.errors.confirmPassword}
            >
              <EuiFieldPassword
                placeholder="Confirm password"
                name="confirmPassword"
                value={createPasswordFormik.values.confirmPassword}
                onChange={createPasswordFormik.handleChange}
                onBlur={createPasswordFormik.handleBlur}
                isInvalid={
                  !!createPasswordFormik.errors.confirmPassword &&
                  createPasswordFormik.touched.confirmPassword
                }
              />
            </EuiFormRow>
            <EuiSpacer />
            <EuiFlexGroup
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <EuiButton
                fullWidth
                fill
                onClick={() => {
                  setPage(2);
                }}
                isDisabled={
                  !createPasswordFormik.values.password ||
                  !createPasswordFormik.values.confirmPassword ||
                  (!!createPasswordFormik.errors.password &&
                    createPasswordFormik.touched.password) ||
                  (!!createPasswordFormik.errors.confirmPassword &&
                    createPasswordFormik.touched.confirmPassword)
                }
              >
                Sign up
              </EuiButton>
              <EuiButtonEmpty
                iconSide="left"
                iconType="arrowLeft"
                onClick={() => {
                  setPage(0);
                }}
              >
                Change email
              </EuiButtonEmpty>
            </EuiFlexGroup>
          </EuiForm>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};

export default PasswordPage;
