import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import { useState } from "react";
import OtpInput from "react-otp-input";
import { validateOtp } from "../../services/register";
import { TimerInstance } from "../timerComponent";
import "./otpPage.css";

const OtpPage = ({
  email,
  userId,
  timer,
  setApiKey,
  setPage,
}: {
  email: string;
  userId: string;
  timer: TimerInstance;
  setApiKey: (apiKey: string) => void;
  setPage: (page: number) => void;
}) => {
  const [otp, setOtp] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const isValidOtp = (otpToCheck: string): boolean => {
    const otpRegex = /^\d{6}$/; // Regex to match exactly 6 numeric characters
    return otpRegex.test(otpToCheck);
  };

  const handleValidateOtp = async () => {
    if (!isValidOtp(otp)) {
      setErrorMessage("Please enter a valid 6-digit OTP.");
      return;
    }

    try {
      setIsLoading(true);
      setErrorMessage(null); // Clear any previous error
      const signUpData = await validateOtp(userId, otp);
      console.log("OTP Validation successful:", signUpData);
      setApiKey(signUpData.User.ApiKey);

      // Navigate to the next page and reset the timer
      setPage(2);
      timer.resetTimer();
    } catch (error: any) {
      console.error("OTP Validation failed:", error.message);
      setErrorMessage("Invalid OTP. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <EuiPanel style={{ width: "400px" }}>
      <EuiFlexGroup
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <EuiFlexItem style={{ width: "100%", textAlign: "left" }}>
          <EuiTitle>
            <h1>Sign up for GEX Terminal</h1>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem style={{ width: "100%", textAlign: "left" }}>
          <EuiText>
            <p>
              Enter the one-time code sent to <strong>{email}</strong>
            </p>
            {timer.isActive ? (
              <p>Your code expires in {timer.getCurrentTime()}s.</p>
            ) : (
              <p>Your code has expired. Please request a new code.</p>
            )}
            {errorMessage && (
              <EuiText color="danger" size="s">
                <p>{errorMessage}</p>
              </EuiText>
            )}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem style={{ width: "100%" }}>
          <EuiFlexGroup
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <OtpInput
              inputStyle="inputStyle"
              value={otp}
              onChange={setOtp}
              numInputs={6}
              inputType="tel"
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              shouldAutoFocus
            />
            <EuiButton
              fullWidth
              fill
              isLoading={isLoading} // EUI will show a spinner on the button
              onClick={handleValidateOtp}
              isDisabled={isLoading || !isValidOtp(otp)}
            >
              Validate Code
            </EuiButton>
            <EuiButtonEmpty
              iconSide="left"
              iconType="arrowLeft"
              onClick={() => {
                setPage(0);
                timer.resetTimer();
              }}
            >
              Change email
            </EuiButtonEmpty>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};

export default OtpPage;
