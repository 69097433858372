import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiPanel,
  EuiSpacer,
  EuiTitle,
} from "@elastic/eui";
import { useFormik } from "formik";
import { register } from "../../services/register";
import BusinessEmailValidatorComponent, {
  BusinessEmailValidatorInstance,
} from "../businessEmailValidator";
import { TimerInstance } from "../timerComponent";

interface FormValues {
  email: string;
}

interface FormErrors {
  email?: string;
}

const EmailPage = ({
  timer,
  setEmail,
  setUserId,
  setPage,
}: {
  email: string;
  timer: TimerInstance;
  setEmail: (email: string) => void;
  setUserId: (userId: string) => void;
  setPage: (page: number) => void;
}) => {
  const emailValidator: BusinessEmailValidatorInstance =
    BusinessEmailValidatorComponent();
  const emailFormik = useFormik({
    initialValues: {
      email: "",
    },
    validateOnChange: true,
    validate: (values: FormValues): FormErrors => {
      const errors: FormErrors = {};

      const emailError = emailValidator.validateEmail(values.email);

      if (emailError) {
        errors.email = emailError; // Assign only if there is an error
      }

      return errors;
    },
    onSubmit: async (values: FormValues) => {
      const email = values.email;
      try {
        // Call the register API
        const signUpData = await register(email);
        console.log("Registration successful:", signUpData);

        // Perform post-registration actions
        setUserId(signUpData.Id);
        setPage(1);
        timer.startTimer();
        setEmail(values.email); // Use the form value directly
      } catch (error: any) {
        console.error("Registration failed:", error.message);
      }
      // TODO: Remove once API is working, this is just a stopgap to move the form along.
      setPage(1);
      timer.startTimer();
      setEmail(values.email); // Use the form value directly
    },
  });
  return (
    <EuiPanel style={{ width: "400px" }}>
      <EuiFlexGroup
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <EuiFlexItem style={{ width: "100%", textAlign: "left" }}>
          <EuiTitle>
            <h1>Sign up for GEX Terminal</h1>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem style={{ width: "100%" }}>
          <EuiForm component="form" onSubmit={emailFormik.handleSubmit}>
            <EuiFormRow
              isInvalid={
                !!emailFormik.errors.email && emailFormik.touched.email
              }
              error={emailFormik.errors.email}
            >
              <EuiFieldText
                type="email"
                placeholder="Business Email"
                name="email"
                value={emailFormik.values.email}
                onChange={emailFormik.handleChange}
                onBlur={emailFormik.handleBlur}
                isInvalid={
                  !!emailFormik.errors.email && emailFormik.touched.email
                }
              />
            </EuiFormRow>
            <EuiSpacer />
            <EuiFlexGroup
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <EuiButton
                fullWidth
                fill
                type="submit"
                isDisabled={
                  !emailFormik.values.email ||
                  (!!emailFormik.errors.email && emailFormik.touched.email)
                }
              >
                Get one-time code
              </EuiButton>
              <EuiButtonEmpty href="/" iconSide="left" iconType="arrowLeft">
                Back to log in
              </EuiButtonEmpty>
            </EuiFlexGroup>
          </EuiForm>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};

export default EmailPage;
