import COMMON_EMAIL_DOMAINS from "../utils/commonEmailDomains";

export interface BusinessEmailValidatorInstance {
  validateEmail: (email: string) => string | undefined;
}

const BusinessEmailValidatorComponent = (): BusinessEmailValidatorInstance => {
  const isBusinessEmail = (email: string): boolean => {
    return !COMMON_EMAIL_DOMAINS.has(email);
  };

  // Helper function to extract the domain from an email
  const extractDomain = (email: string): string | null => {
    const match = email.match(/@([a-zA-Z0-9.-]+)$/); // Extract domain after "@"
    return match ? match[1] : null;
  };

  // Helper function to validate email format
  const isEmailAddress = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validateEmail = (email: string): string | undefined => {
    // Derive validation state
    const emailDomain = extractDomain(email);

    if (!isEmailAddress(email)) {
      return "Not an email address.";
    }
    if (!!emailDomain && !isBusinessEmail(emailDomain)) {
      return "Not a business email address.";
    }
  };

  return {
    validateEmail,
  };
};

export default BusinessEmailValidatorComponent;
