// Import Axios
import axios, { AxiosHeaders, AxiosInstance, AxiosResponse } from "axios";

// Define a generic type for API responses
type ApiResponse<T> = {
  data: T;
  status: number;
  message?: string;
};

const BASE_URL = "https://accounts.greenearthx.com";

// Define the default headers using AxiosHeaders
const DEFAULT_HEADERS = new AxiosHeaders({
  "Content-Type": "application/json",
  Accept: "application/json",
  ApiRootRequired: "Y",
  IncludedModelFields: "all", // Adjust based on your requirements
});

// Create an API handler class
class ApiHandler {
  private axiosInstance: AxiosInstance;

  constructor(timeout = 5000) {
    const headers = DEFAULT_HEADERS;

    this.axiosInstance = axios.create({
      baseURL: BASE_URL,
      timeout,
      headers,
    });

    // Interceptor to handle responses
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;
        if (response) {
          console.error(
            `API Error: ${response.status} - ${response.statusText}`
          );
        } else {
          console.error("Network Error:", error.message);
        }
        return Promise.reject(error);
      }
    );
  }

  // Generic GET method with optional headers
  async get<T>(
    url: string,
    params?: Record<string, unknown>
  ): Promise<ApiResponse<T>> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.get(url, {
        params,
      });
      return { data: response.data, status: response.status };
    } catch (error: any) {
      return this.handleError<T>(error);
    }
  }

  // Generic POST method with optional headers
  async post<T>(
    url: string,
    data?: Record<string, unknown>,
    headers?: Record<string, string>
  ): Promise<ApiResponse<T>> {
    console.log(
      new AxiosHeaders({
        ...this.axiosInstance.defaults.headers,
        ...headers,
      })
    );
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.post(
        url,
        data,
        headers
      );
      return { data: response.data, status: response.status };
    } catch (error: any) {
      return this.handleError<T>(error);
    }
  }

  // Generic PUT method with optional headers
  async put<T>(
    url: string,
    data?: Record<string, unknown>
  ): Promise<ApiResponse<T>> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.put(
        url,
        data
      );
      return { data: response.data, status: response.status };
    } catch (error: any) {
      return this.handleError<T>(error);
    }
  }

  // Generic DELETE method with optional headers
  async delete<T>(url: string): Promise<ApiResponse<T>> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.delete(url);
      return { data: response.data, status: response.status };
    } catch (error: any) {
      return this.handleError<T>(error);
    }
  }

  // Error handler
  private handleError<T>(error: any): ApiResponse<T> {
    if (error.response) {
      const { status, data } = error.response;
      return { data, status, message: data?.message || "An error occurred" };
    } else {
      return {
        data: {} as T,
        status: 500,
        message: error.message || "Network Error",
      };
    }
  }
}

export default ApiHandler;
